/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

get_viewport_size = function () {
	var e = window, a = 'inner';
	if (!('innerWidth' in window )) {
		a = 'client';
		e = document.documentElement || document.body;
	}
	return {width: e[a + 'Width'], height: e[a + 'Height']};
};  

document.addEventListener('DOMContentLoaded', function() {
	
	// Get all "navbar-burger" elements
	var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {
  
		// Add a click event on each of them
		$navbarBurgers.forEach( function(el) {
			el.addEventListener('click', function() {
  
			// Get the target from the "data-target" attribute
			var target = el.dataset.target;
			var $target = document.getElementById(target);
  
			// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
			el.classList.toggle('is-active');
			$target.classList.toggle('is-active');
			});
		});
	}
  
	/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
	var prevScrollpos = window.pageYOffset;
	window.onscroll = function() {
	  var currentScrollPos = window.pageYOffset;
	  console.log(get_viewport_size());
	  if(get_viewport_size().width > 768) {
		if (prevScrollpos > currentScrollPos || currentScrollPos == 0) {
		  document.getElementById("navbar").style.top = "0";
		} else {
		  document.getElementById("navbar").style.top = "-50px";
		}
		prevScrollpos = currentScrollPos;
	  }
	};  // <- Removed extra closing parenthesis here
  });  // Properly closes DOMContentLoaded function
  
  
if( document.querySelector('.home') ) {

	var myHeader = new Swiper('.swiper-header', {
	// Optional parameters
	autoHeight: false,
	direction: 'horizontal',
	loop: true,
	lazy: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
	});

}

if( document.querySelector('.swiper-quotes') ) {

	var swiperQuotes = new Swiper('.swiper-quotes', {
	// Optional parameters
	autoHeight: true,
	direction: 'horizontal',
	loop: true,
	lazy: true,
	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
	});

}

if( document.querySelector('.swiper-container-projects') ) {
	var swiperProjects = new Swiper('.swiper-container-projects', {
	// Optional parameters
	direction: 'horizontal',
	autoHeight: false,
	loop: true,
	lazy: true,
	centeredSlides: true,
	slidesPerView: 1,
	spaceBetween: 10, 
	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},
	breakpoints: {
		640: {
		slidesPerView: 2,
		spaceBetween: 20,
		},
		1024: {
		slidesPerView: 2,
		spaceBetween: 0,
		},
	},
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
	});
}


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  



})(jQuery);
