// 2. This code loads the IFrame Player API code asynchronously.
var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

function onYouTubeIframeAPIReady() {
  // Check all slides and initialize video players
  var swiper = document.getElementsByClassName('swiper-container-videos');
  if( swiper.length != 0 ) {
  var slides = swiper[0].getElementsByClassName('swiper-slide');
    var players = [];
    
    for (var i=0; i < slides.length; i++) {
      var element = slides[i].getElementsByClassName('yt-player')[0];
      var id = element.getAttribute('data-id');
      
      var player = new YT.Player(element, {
        height: '315',
        width: '560',
        videoId: id,
        playerVars: {
          autoplay: false,
          color: '#7fbc03',
          modestbranding: 1,
          rel: 0,
          title: 0,
          playsinline: 1,
          enablejsapi: 1
        }
      });
      players.push(player);
    }  
  
  }
  var mySwiper = new Swiper('.swiper-container-videos', {
    effect: 'slide',
    spaceBetween: 0,
    loop: true,

    slidesPerView: 1,
    centeredSlides: false,
    
    shortSwipes: true,
    runCallbacksOnInit: false,
    normalizeSlideIndex	: false,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    on: {
      slideChange: function() {                
        var arrayLength = players.length;
        for (var i = 0; i < arrayLength; i++) {
            players[i].pauseVideo();
            document.querySelector('.swiper-slide').classList.remove('video-playing');
        }
      },
      afterInit: function() {
        
        setTimeout(function(){  
          
          var videos = document.getElementsByClassName('video-container')[0].clientHeight;
          var elem = document.getElementsByClassName('swiper-pagination');
          var elem2 = document.getElementsByClassName('swiper-button-prev');
          var elem3 = document.getElementsByClassName('swiper-button-next');
          elem[0].style.top = videos - 45 + 'px';
          elem2[0].style.top = (videos / 2 ) + 15 + 'px';
          elem3[0].style.top = (videos / 2 ) + 15 + 'px';
        }, 2000);

      
      }
    }
  });
  
  window.addEventListener('resize', function(event) {
    var videos = document.getElementsByClassName('video-container')[0].clientHeight;
    var elem = document.getElementsByClassName('swiper-pagination');
    var elem2 = document.getElementsByClassName('swiper-button-prev');
    var elem3 = document.getElementsByClassName('swiper-button-next');
      elem2[0].style.top = videos - 45 + 'px';
      elem3[0].style.top = videos - 45 + 'px';
      
    elem[0].style.top = videos - 45 + 'px';
  }, true);
  
}


